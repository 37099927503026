import React from 'react';
import { Button, Flex } from '@chakra-ui/react';

export default function AddNewCategory({ onOpen }) {
  return (
    <Flex justifyContent="end">
      <Button colorScheme="blue" gap="2" onClick={onOpen}>
        Add Sub Catagory <i className="fa-solid fa-plus"></i>
      </Button>
    </Flex>
  );
}
