import { createContext, useContext, useState } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          bg="rgba(0, 0, 0, 0.3)"
          align="center"
          justify="center"
          zIndex="1000"
        >
          <Spinner
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
          {/* <Spinner size="lg" speed="0.65s" color="blue.500" /> */}
        </Flex>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
