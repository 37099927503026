import { useState, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useLoading } from 'contexts/LoadingContext';

const useBanner = () => {
  const [error, setError] = useState(null);
  const [banners, setBanners] = useState([]);
  const { setIsLoading } = useLoading();
  const [registeredBanner, setRegisteredBanner] = useState(null);
  const toast = useToast({ position: 'top' });

  const fetchBanner = useCallback(async (page = 1) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/banner/all?page=${page}`,
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );
      // console.log(response);

      if (response.data.success) {
        setBanners({
          data: response.data.data,
          totalPages: response.data.totalPages,
          currentPage: page,
        });
      } else {
        setError(response.data.message || 'Failed to fetch banner.');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const registerBanner = async (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/banner/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        const newBanner = response.data.data;
        setRegisteredBanner(newBanner);

        toast({
          title: 'Success',
          description: 'New banner added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.data.message || 'Failed to add new banner');
        toast({
          title: 'Error',
          description: response.data.message || 'Failed to add new banner',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setError('An error occurred while adding the banner. Please try again.');
      toast({
        title: 'Error',
        description:
          'An error occurred while adding the banner. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    registeredBanner,
    registerBanner,
    fetchBanner,
    banners,
    error,
  };
};

export default useBanner;
