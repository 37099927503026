'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import useOffer from 'hooks/offerApis/useOffer';
import usePagination from 'hooks/usePagination';
import useOfferActions from 'hooks/offerApis/useOfferActions';
import OfferPopup from 'components/popups/offerPopup/OfferPopup';
import ReusableTable from 'components/reusableTable/ReusableTable';
import Pagination from '../../../../components/pagination/Pagination';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import SwitchToggler from 'components/popups/updateStatusPopup/SwitchToggler';
import GenericDetailView from 'components/popups/GenericDetailView/GenericDetailView';
import { useSearch } from 'contexts/SearchContext';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const toast = useToast({ position: 'top' });
  const { offer, error, fetchOffer, registerOffer } = useOffer();
  const { deleteOffer } = useOfferActions();
  const { searchResults, searchQuery } = useSearch();
  // console.log(searchResults);

  const totalPages = searchQuery
    ? searchResults.totalPages || 1
    : offer.totalPages || 1;
  const { currentPage, handlePageChange } = usePagination(totalPages);

  React.useEffect(() => {
    if (!searchQuery) {
      fetchOffer(currentPage);
    }
  }, [fetchOffer, currentPage, searchQuery]);

  const handleAddNewOffer = async (formData) => {
    try {
      await registerOffer(formData);
      await fetchOffer(currentPage);
    } catch (error) {
      toast({
        title: 'Adding Failed',
        description: error.message || 'Failed to add offer.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteOffer(id);
      await fetchOffer(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to delete offer.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return offer.data || [];
  }, [offer.data, searchResults.data, searchQuery]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700" width="35px">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('offerName', {
      id: 'offerName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          OFFER NAME
        </Text>
      ),
      cell: (info) => {
        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              whiteSpace="nowrap"
            >
              {info.getValue() || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('offerDescription', {
      id: 'offerDescription',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          DESCRIPTION
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            <GenericDetailView
              type="text"
              content={info.getValue() || 'NA'}
              title="Description"
              maxChars={20}
              truncateType="chars"
            />
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('applicableOn', {
      id: 'applicableOn',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          APPLICABLE ON
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('offerValidOn', {
      id: 'offerValidOn',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          VALID ON
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('offerType', {
      id: 'offerType',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          OFFER TYPE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('offerValue', {
      id: 'offerValue',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          OFFER VALUE
        </Text>
      ),
      cell: ({ row }) => {
        const offerType = row.original.offerType;
        let offerValue = row.original.offerValue;
        if (offerType == 'product') {
          offerValue = offerValue + 1;
          return offerValue;
        }
        return (
          <Flex align="center">
            <Text width="80px" color={textColor} fontSize="sm" fontWeight="700">
              {offerValue || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('offerValidity', {
      id: 'offerValidity',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          VALIDITY
        </Text>
      ),
      cell: (info) => {
        return (
          <Text width="90px" color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        );
      },
    }),
    columnHelper.accessor('isActive', {
      id: 'isActive',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          <SwitchToggler
            entityType="offer"
            entityId={row.original._id}
            initialStatus={row.original.isActive}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
      cell: ({ row }) => {
        return (
          <Flex align="center">
            {row.original.isDeleted ? (
              <Text color="gray.400" fontStyle="italic">
                Deleted
              </Text>
            ) : (
              <>
                <DeleteButton
                  entityType="offer"
                  entityId={row.original._id}
                  onDelete={() => handleDelete(row.original._id)}
                />
              </>
            )}
          </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description: offer.message || 'Failed to fetch offers.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <OfferPopup
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddNewOffer}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
