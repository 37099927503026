import { useState, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useLoading } from 'contexts/LoadingContext';

const useCategoryProduct = () => {
  const [categoryProduct, setCategoryProduct] = useState([]);
  const { setIsLoading } = useLoading();
  const [error, setError] = useState(null);

  const [registeredCategoryProduct, setRegisteredCategoryProduct] =
    useState(null);
  const toast = useToast({ position: 'top' });

  const fetchCategoryProduct = useCallback(async (page = 1) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/product/fetch/all?page=${page}`,
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );
      // console.log(response);

      if (response.data.success) {
        setCategoryProduct({
          data: response.data.data,
          totalPages: response.data.pagination.totalPages,
          currentPage: page,
        });
      } else {
        setError(response.data.message || 'Failed to fetch products');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const registerCategoryProduct = async (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/product/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        const newProduct = response.data.data;
        setRegisteredCategoryProduct(newProduct);

        toast({
          title: 'Success',
          description: 'New product added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.data.message || 'Failed to add new product');
        toast({
          title: 'Error',
          description: response.data.message || 'Failed to add new product',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setError('An error occurred while adding the product. Please try again.');
      toast({
        title: 'Error',
        description:
          'An error occurred while adding the product. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    registeredCategoryProduct,
    registerCategoryProduct,
    fetchCategoryProduct,
    categoryProduct,
    error,
  };
};

export default useCategoryProduct;
