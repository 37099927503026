import {
  Tooltip,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useState } from 'react';

const EditButton = ({
  entityType,
  EditComponent,
  initialData,
  onConfirm,
  admins,
  categories,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState(initialData || {});
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmEdit = async () => {
    setIsLoading(true);
    const finalFormData = new FormData();
    for (const key in formData) {
      finalFormData.append(key, formData[key]);
    }
    try {
      // for (let pair of finalFormData.entries()) {
      //   console.log(pair[0], pair[1]);
      // }
      await onConfirm(formData);
    } catch (error) {
      console.error(`Error editing ${entityType}:`, error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Tooltip label="Edit">
        <IconButton
          icon={<EditIcon boxSize={4} />}
          size="sm"
          colorScheme="blue"
          variant="ghost"
          onClick={onOpen}
          aria-label="Edit"
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit {entityType}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EditComponent
              formData={formData}
              setFormData={setFormData}
              admins={admins}
              categories={categories}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirmEdit}
              isLoading={isLoading}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditButton;
