import { useState, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useLoading } from 'contexts/LoadingContext';

const usePartner = () => {
  const { setIsLoading } = useLoading();
  const [error, setError] = useState(null);
  const [partners, setPartners] = useState([]);
  const [registeredPartner, setRegisteredPartner] = useState(null);
  const toast = useToast({ position: 'top' });

  const fetchPartners = useCallback(
    async (page = 1) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}api/v1/admin/partner/all?page=${page}`,
          {
            validateStatus: function (status) {
              return status >= 200 && status < 500;
            },
          },
        );
        // console.log(response);

        if (response.data.success) {
          setPartners({
            data: response.data.data,
            totalPages: response.data.totalPages,
            currentPage: page,
          });
        } else {
          setError(response.data.message || 'Failed to fetch partners');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading],
  );

  const registerPartner = async (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/partner/register`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        const newPartner = response.data.data;
        setRegisteredPartner(newPartner);

        toast({
          title: 'Success',
          description: 'New partner added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.data.message || 'Failed to add new partner');
        toast({
          title: 'Error',
          description: response.data.message || 'Failed to add new partner',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setError('An error occurred while adding the partner. Please try again.');
      toast({
        title: 'Error',
        description:
          'An error occurred while adding the partner. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    registeredPartner,
    registerPartner,
    fetchPartners,
    partners,
    error,
  };
};

export default usePartner;
