// AssignPartnerPopup.js
import { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';

const AssignPartnerPopup = ({ isOpen, onClose, partners, onAssign }) => {
  const [selectedPartnerId, setSelectedPartnerId] = useState('');

  const handleAssignClick = () => {
    onAssign(selectedPartnerId);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Partner</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            placeholder="Select partner"
            onChange={(e) => setSelectedPartnerId(e.target.value)}
          >
            {partners.data?.map((p) => (
              <option key={p._id} value={p._id}>
                {p.name}
              </option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleAssignClick}>
            Continue
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AssignPartnerPopup;
