import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import { useLoading } from 'contexts/LoadingContext';

const useOfferActions = () => {
  const { setIsLoading } = useLoading();
  const toast = useToast({ position: 'top' });
  const { token } = useAuth();

  const updateOfferStatus = async (offerId, newStatus) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/offers/change-status/?id=${offerId}`,
        { isActive: newStatus },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Status Updated',
          description:
            response.data.message ||
            'Offer status has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message || 'Failed to update offer status.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update offer status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
  };

  const deleteOffer = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/offers/delete/?id=${id}`,
        { isDeleted: true },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      // console.log(response.data);

      if (response.data.success) {
        toast({
          title: 'Offer Deleted',
          description:
            response.data.message || 'Offer has been deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          // title:
          //   'Account is already suspended, kindly contact the support team',
          description:
            response.data.message || 'Offer has been deleted successfully.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting Offer:', error);
      toast({
        title: 'Delete Failed',
        description: error.message || 'Failed to delete offer.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  //   const updateOfferDetails = async (offerId, updatedData) => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.put(
  //         `${process.env.REACT_APP_BASE_URL}api/v1/admin/membership/update/?id=${offerId}`,
  //         updatedData,
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: `${token}`,
  //           },
  //           validateStatus: function (status) {
  //             return status >= 200 && status < 500;
  //           },
  //         },
  //       );

  //       if (response.data.success) {
  //         toast({
  //           title: 'Offer Updated',
  //           description:
  //             'Offer details have been updated successfully.',
  //           status: 'success',
  //           duration: 3000,
  //           isClosable: true,
  //         });
  //         return true;
  //       } else {
  //         toast({
  //           title: 'Update Failed',
  //           description:
  //             response.data.message ||
  //             'Failed to update Offer details.',
  //           status: 'error',
  //           duration: 3000,
  //           isClosable: true,
  //         });
  //         return false;
  //       }
  //     } catch (error) {
  //       console.error('Error updating Offer:', error);
  //       toast({
  //         title: 'Update Failed',
  //         description:
  //           error.message || 'Failed to update Offer details.',
  //         status: 'error',
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //       return false;
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  return {
    updateOfferStatus,
    deleteOffer,
    // updateOfferDetails,
  };
};

export default useOfferActions;
