'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Spinner,
  Button,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import usePagination from 'hooks/usePagination';
import useCategory from 'hooks/categoryApis/useCategory';
import ReusableTable from 'components/reusableTable/ReusableTable';
// import EditButton from 'components/popups/editButtonPopup/EditButton';
import Pagination from '../../../../components/pagination/Pagination';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import SwitchToggler from 'components/popups/updateStatusPopup/SwitchToggler';
import useCategoryProduct from 'hooks/categoryProductApis/useCategoryProduct';
import GenericDetailView from 'components/popups/GenericDetailView/GenericDetailView';
import CategoryProductPopup from 'components/popups/categoryProductPopup/CategoryProduct';
import useCategoryProductActions from 'hooks/categoryProductApis/useCategoryProductActions';
// import EditCategoryProductDetails from 'components/popups/categoryProductPopup/EditCategoryProduct';
// import EditCategoryProductButton from 'components/popups/categoryProductPopup/EditCategoryProductButton';
// import OptionsColumn from 'components/popups/categoryProductPopup/OptionsColumn';
import { useSearch } from 'contexts/SearchContext';
import EditButton from 'components/popups/editButtonPopup/EditButton';
import EditProductDetails from 'components/popups/categoryProductPopup/EditProduct';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const toast = useToast({ position: 'top' });
  const {
    categoryProduct,
    error,
    fetchCategoryProduct,
    registerCategoryProduct,
  } = useCategoryProduct();
  const { deleteCategoryProduct, updateCategoryProductDetails } =
    useCategoryProductActions();
  const { category, fetchCategory } = useCategory();
  const { searchResults, searchQuery } = useSearch();
  // console.log(searchResults);
  const totalPages = searchQuery
    ? searchResults.totalPages || 1
    : categoryProduct.totalPages || 1;
  const { currentPage, handlePageChange } = usePagination(totalPages);

  React.useEffect(() => {
    if (!searchQuery) {
      fetchCategoryProduct(currentPage);
      fetchCategory();
    }
  }, [fetchCategoryProduct, currentPage, searchQuery]);

  const handleAddCategoryProduct = async (formData) => {
    try {
      await registerCategoryProduct(formData);
      await fetchCategoryProduct(currentPage);
    } catch (error) {
      toast({
        title: 'Adding Failed',
        description: error.message || 'Failed to add product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditProduct = async (partnerId, userData) => {
    try {
      await updateCategoryProductDetails(partnerId, userData);
      await fetchCategoryProduct(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteCategoryProduct(id);
      await fetchCategoryProduct(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to delete admin.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return categoryProduct.data || [];
  }, [categoryProduct.data, searchResults.data, searchQuery]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700" width="35px">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('image', {
      id: 'image',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          IMAGE
        </Text>
      ),
      cell: (info) => {
        return (
          <Flex align="center" color={textColor}>
            <GenericDetailView
              type="image"
              content={info.getValue()}
              title="Image Preview"
              imageAlt="Product Image"
            />
          </Flex>
        );
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('categoryName', {
      id: 'categoryName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          Catagory Name
        </Text>
      ),
      cell: ({ row }) => {
        const categoryName = row.original.categoryId?.name;

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              width="120px"
              whiteSpace="nowrap"
            >
              {categoryName || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('subCategoryName', {
      id: 'subCategoryName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          SubCat. Name
        </Text>
      ),
      cell: ({ row }) => {
        const subCategoryName = row.original.subcategoryId?.name;

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              width="120px"
              whiteSpace="nowrap"
            >
              {subCategoryName || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('categoryPosition', {
      id: 'categoryPosition',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          Position
        </Text>
      ),
      cell: ({ row }) => {
        const categoryPosition = row.original?.position;

        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {categoryPosition || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('duration', {
      id: 'duration',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          Duration
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('price', {
      id: 'price',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          PRICE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {`₹${info.getValue()}`}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('discountPercentage', {
      id: 'discountPercentage',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          DISCOUNT %
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700" width="61px">
            {`${info.getValue()} %`}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('details', {
      id: 'details',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          DETAILS
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text width="170px" color={textColor} fontSize="sm" fontWeight="700">
            <GenericDetailView
              type="text"
              content={info.getValue() || 'NA'}
              title="Details"
              maxChars={20}
              truncateType="chars"
            />
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('isActive', {
      id: 'isActive',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          <SwitchToggler
            entityType="product"
            entityId={row.original._id}
            initialStatus={row.original.isActive}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
      cell: ({ row }) => {
        return (
          <Flex align="center">
            <EditButton
              entityType="category-product"
              categories={category}
              EditComponent={EditProductDetails}
              initialData={row.original}
              onConfirm={(updatedData) =>
                handleEditProduct(row.original._id, updatedData)
              }
            />
            {row.original.isDeleted ? (
              <Text color="gray.400" fontStyle="italic">
                Deleted
              </Text>
            ) : (
              <>
                <DeleteButton
                  entityType="admin"
                  entityId={row.original._id}
                  onDelete={() => handleDelete(row.original._id)}
                />
              </>
            )}
          </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description: category.message || 'Failed to fetch category.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <CategoryProductPopup
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddCategoryProduct}
          categories={category}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
