import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdAdminPanelSettings,
  MdCardMembership,
  MdCategory,
  MdEventNote,
  MdFeedback,
  MdHome,
  MdInventory,
  MdLocalOffer,
  MdNotifications,
  MdOutlineInventory2,
  MdPeople,
  MdPerson,
  MdPhoto,
  MdReportProblem,
  MdSchedule,
  MdSpa,
} from 'react-icons/md';

// Admin Imports
import SlotTable from 'views/admin/slotsTable';
import MainDashboard from 'views/admin/default';
import AdminTable from 'views/admin/adminTable';
import OfferTable from 'views/admin/offerTable';
import StocksTable from 'views/admin/stocksTable';
import BannerTable from 'views/admin/bannerTable';
import PartnerTable from 'views/admin/partnerTable';
import ProductTable from 'views/admin/productTable';
import BookingTable from 'views/admin/bookingTable';
import CustomerTable from 'views/admin/customerTable';
import CatagoryTable from 'views/admin/catagoryTable';
// import PackageTable from 'views/admin/packageTable';
import FeedbackTable from 'views/admin/feedbackTable';
import ComplaintTable from 'views/admin/complainTable';
import MembershipTable from 'views/admin/membershipTable';
import SubCatagoryTable from 'views/admin/subCatagoryTable';
import StockBookingTable from 'views/admin/stockBookingTable';
import NotificationTable from 'views/admin/notificationTable';
// import StockAssignmentTable from 'views/admin/stockAssignmentTable';

const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
    roles: ['all'],
  },
  {
    name: 'Admin',
    layout: '/admin',
    path: '/admin-table',
    icon: (
      <Icon
        as={MdAdminPanelSettings}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <AdminTable />,
    roles: ['all', 'admin_manager'],
  },
  {
    name: 'Customer',
    layout: '/admin',
    path: '/customer',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <CustomerTable />,
    roles: ['all', 'customer_manager'],
  },
  {
    name: 'Partner',
    layout: '/admin',
    path: '/partner',
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: <PartnerTable />,
    roles: ['all', 'partner_manager'],
  },
  {
    name: 'Customer Booking',
    layout: '/admin',
    path: '/booking',
    icon: <Icon as={MdEventNote} width="20px" height="20px" color="inherit" />,
    component: <BookingTable />,
    roles: ['all', 'booking_manager'],
  },
  {
    name: 'Partner Booking',
    layout: '/admin',
    path: '/stock-booking',
    icon: (
      <Icon
        as={MdOutlineInventory2}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <StockBookingTable />,
    roles: ['all', 'booking_manager'],
  },
  {
    name: 'Stock Item',
    layout: '/admin',
    path: '/stock-table',
    icon: <Icon as={MdInventory} width="20px" height="20px" color="inherit" />,
    component: <StocksTable />,
    roles: ['all', 'stock_manager'],
  },
  {
    name: 'App Notification',
    layout: '/admin',
    path: '/notification',
    icon: (
      <Icon as={MdNotifications} width="20px" height="20px" color="inherit" />
    ),
    component: <NotificationTable />,
    roles: ['all', 'notification_manager'],
  },
  {
    name: 'Feedback',
    layout: '/admin',
    path: '/feedback',
    icon: <Icon as={MdFeedback} width="20px" height="20px" color="inherit" />,
    component: <FeedbackTable />,
    roles: ['all', 'feedback_manager'],
  },
  {
    name: 'Complaint',
    layout: '/admin',
    path: '/complaints',
    icon: (
      <Icon as={MdReportProblem} width="20px" height="20px" color="inherit" />
    ),
    component: <ComplaintTable />,
    roles: ['all', 'complaint_manager'],
  },
  {
    name: 'App Banner',
    layout: '/admin',
    path: '/banner',
    icon: <Icon as={MdPhoto} width="20px" height="20px" color="inherit" />,
    component: <BannerTable />,
    roles: ['all', 'banner_manager'],
  },
  {
    name: 'Membership',
    layout: '/admin',
    path: '/membership',
    icon: (
      <Icon as={MdCardMembership} width="20px" height="20px" color="inherit" />
    ),
    component: <MembershipTable />,
    roles: ['all', 'membership_manager'],
  },
  {
    name: 'Offer',
    layout: '/admin',
    path: '/offers',
    icon: <Icon as={MdLocalOffer} width="20px" height="20px" color="inherit" />,
    component: <OfferTable />,
    roles: ['all', 'offer_manager'],
  },
  {
    name: 'Booking Slot',
    layout: '/admin',
    path: '/slots',
    icon: <Icon as={MdSchedule} width="20px" height="20px" color="inherit" />,
    component: <SlotTable />,
    roles: ['all', 'slot_manager'],
  },
  {
    name: 'Service Category',
    layout: '/admin',
    path: '/category',
    icon: <Icon as={MdCategory} width="20px" height="20px" color="inherit" />,
    component: <CatagoryTable />,
    roles: ['all', 'category_manager'],
  },
  {
    name: 'Service Sub Category',
    layout: '/admin',
    path: '/sub-category',
    icon: <Icon as={MdCategory} width="20px" height="20px" color="inherit" />,
    component: <SubCatagoryTable />,
    roles: ['all', 'category_manager'],
  },
  {
    name: 'Service',
    layout: '/admin',
    path: '/products',
    icon: <Icon as={MdSpa} width="20px" height="20px" color="inherit" />,
    component: <ProductTable />,
    roles: ['all', 'product_manager'],
  },
];

export default routes;
