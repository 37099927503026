import {
  Tooltip,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useState } from 'react';

const EditStockButton = ({
  entityType,
  EditComponent,
  initialData,
  onConfirm,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState(initialData || {});
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmEdit = async () => {
    setIsLoading(true);
    const finalFormData = new FormData();
    let oldImages = [];
    let hasNewImages = false;

    for (const key in formData) {
      if (key === 'image' && Array.isArray(formData.image)) {
        formData.image.forEach((file) => {
          if (
            typeof file === 'string' &&
            file.trim() !== '' &&
            file !== '[object File]'
          ) {
            // If the image is a URL (existing image), add to oldImages
            oldImages.push(file);
          } else if (file instanceof File) {
            // If it's a new uploaded file, append to FormData
            finalFormData.append(`images`, file);
            hasNewImages = true;
          }
        });
      } else {
        finalFormData.append(key, formData[key]);
      }
    }

    // Remove empty strings from oldImages
    oldImages = oldImages.filter((img) => img.trim() !== '');

    // If no new images and oldImages is empty, prevent submission
    if (!hasNewImages && oldImages.length === 0) {
      console.warn('No images to submit.');
      setIsLoading(false);
      return;
    }

    // Append oldImages as a JSON string if it has valid values
    if (oldImages.length > 0) {
      finalFormData.append('oldImages', JSON.stringify(oldImages));
    }

    try {
      await onConfirm(finalFormData);
    } catch (error) {
      console.error(`Error editing ${entityType}:`, error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  // const handleConfirmEdit = async () => {
  //   setIsLoading(true);
  //   const finalFormData = new FormData();
  //   let oldImages = [];

  //   for (const key in formData) {
  //     if (key === 'image' && Array.isArray(formData.image)) {
  //       // console.log('Inside if block');

  //       formData.image.forEach((file, index) => {
  //         if (typeof file === 'string') {
  //           // If the image is a URL (existing image), add to oldImages array
  //           oldImages.push(file);
  //         } else {
  //           // If it's a file, append to FormData for upload
  //           finalFormData.append(`images`, file);
  //         }
  //       });
  //     } else {
  //       finalFormData.append(key, formData[key]);
  //     }
  //   }

  //   // Append oldImages as a JSON string
  //   finalFormData.append('oldImages', JSON.stringify(oldImages));

  //   try {
  //     // Debugging: Check formData contents
  //     // for (let pair of finalFormData.entries()) {
  //     //   console.log(pair[0], pair[1]);
  //     // }

  //     await onConfirm(finalFormData);
  //   } catch (error) {
  //     console.error(`Error editing ${entityType}:`, error);
  //   } finally {
  //     setIsLoading(false);
  //     onClose();
  //   }
  // };

  // const handleConfirmEdit = async () => {
  //   setIsLoading(true);
  //   const finalFormData = new FormData();
  //   for (const key in formData) {
  //     if (key === 'image' && Array.isArray(formData.image)) {
  //       console.log('Inside if block');

  //       formData.image.forEach((file, index) => {
  //         finalFormData.append(`images`, file); // Backend will receive multiple 'image' fields
  //       });
  //     } else {
  //       finalFormData.append(key, formData[key]);
  //     }
  //   }
  //   try {
  //       // for (let pair of finalFormData.entries()) {
  //       //   console.log(pair[0], pair[1]);
  //       // }
  //     await onConfirm(finalFormData);
  //   } catch (error) {
  //     console.error(`Error editing ${entityType}:`, error);
  //   } finally {
  //     setIsLoading(false);
  //     onClose();
  //   }
  // };

  return (
    <>
      <Tooltip label="Edit">
        <IconButton
          icon={<EditIcon boxSize={4} />}
          size="sm"
          colorScheme="blue"
          variant="ghost"
          onClick={onOpen}
          aria-label="Edit"
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit {entityType}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EditComponent formData={formData} setFormData={setFormData} />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirmEdit}
              isLoading={isLoading}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditStockButton;
