import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';
import { useLoading } from 'contexts/LoadingContext';

const usePartnerActions = () => {
  const { setIsLoading } = useLoading();
  const toast = useToast({ position: 'top' });
  const { token } = useAuth();

  const updatePartnerStatus = async (partnerId, newStatus) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/partner/change-status/?id=${partnerId}`,
        { isActive: newStatus },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Status Updated',
          description: 'Partner status has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message || 'Failed to update partner status.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update partner status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
  };

  const deletePartner = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/partner/delete/?id=${id}`,
        { isDeleted: true },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      // console.log(response.data);

      if (response.data.success) {
        toast({
          title: 'Partner Deleted',
          description:
            response.data.message || 'Partner has been deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          // title:
          //   'Account is already suspended, kindly contact the support team',
          description:
            response.data.message || 'Partner has been deleted successfully.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting partner:', error);
      toast({
        title: 'Delete Failed',
        description: error.message || 'Failed to delete partner.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const updatePartnerDetails = async (partnerId, updatedData) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/partner/update/?id=${partnerId}`,
        updatedData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Partner Updated',
          description: 'Partner details have been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message || 'Failed to update partner details.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error updating partner:', error);
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update partner details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const downloadExcel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/partner/download-excel`,
        {
          responseType: 'blob',
        },
      );

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'partners.xlsx'); // Set the filename

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the DOM by removing the link
      document.body.removeChild(link);

      // Success toast
      toast({
        title: 'Download successful!',
        description: 'Partner data has been downloaded.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Error toast
      toast({
        title: 'Download failed!',
        description: 'Something went wrong while downloading the partner data.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return {
    updatePartnerDetails,
    updatePartnerStatus,
    deletePartner,
    downloadExcel,
  };
};

export default usePartnerActions;
