import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AdminLayout from './layouts/admin';
import AdminLogin from './pages/admin/index';

import {
  ChakraProvider,
  Flex,
  Spinner,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme';
import { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { AuthProvider } from 'contexts/AuthContext';
import { SearchProvider } from 'contexts/SearchContext';
import { LoadingProvider } from 'contexts/LoadingContext';
// Chakra imports

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <Flex align="center" justify="center" h="60vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="lg"
        />
      </Flex>
    );
  }

  if (!user) {
    return <Navigate to="/admin/sign-in" replace />;
  }

  return children;
};

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      <LoadingProvider>
        <AuthProvider>
          <SearchProvider>
            <Routes>
              <Route
                path="admin/*"
                element={
                  <ProtectedRoute>
                    <AdminLayout
                      theme={currentTheme}
                      setTheme={setCurrentTheme}
                    />
                  </ProtectedRoute>
                }
              />
              <Route path="/" element={<Navigate to="/admin" replace />} />
              <Route path="/admin/sign-in" element={<AdminLogin />} />
            </Routes>
          </SearchProvider>
        </AuthProvider>
      </LoadingProvider>
    </ChakraProvider>
  );
}

// const roleBasedRoutes = {
//   admin_manager: '/admin/admin-table',
//   customer_manager: '/admin/customer',
//   partner_manager: '/admin/partner',
//   stock_manager: '/admin/stock',
//   category_manager: '/admin/category',
//   banner_manager: '/admin/banner',
//   product_manager: '/admin/products',
//   membership_manager: '/admin/membership',
//   feedback_manager: '/admin/feedback',
//   complaint_manager: '/admin/complaints',
//   notification_manager: '/admin/notification',
//   offer_manager: '/admin/offers',
//   slot_manager: '/admin/slots',
//   booking_manager: '/admin/booking',
// };
