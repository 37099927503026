import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
const CreateAdmin = ({ isOpen, onClose, onConfirm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    roles: [],
    password: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    username: '',
    email: '',
    roles: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Clear specific error for this field
    setErrors((prev) => ({
      ...prev,
      [name]: value.trim()
        ? ''
        : `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
    }));
  };

  const handleRoleChange = (selectedRoles) => {
    setFormData((prev) => ({ ...prev, roles: selectedRoles }));

    // Update error message for roles if empty
    setErrors((prev) => ({
      ...prev,
      roles: selectedRoles.length === 0 ? 'At least one role is required' : '',
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.username.trim()) newErrors.username = 'Username is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    if (formData.roles.length === 0) newErrors.roles = 'Role is required';
    if (!formData.password.trim()) newErrors.password = 'Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const dataToSubmit = {
        ...formData,
        role: formData.roles.join(','),
      };
      setIsLoading(true);
      try {
        await onConfirm(dataToSubmit);
        onClose();
      } catch (error) {
        console.error('Error adding admin:', error);
        setErrors((prev) => ({
          ...prev,
          form: 'Error adding admin. Please try again.',
        }));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Admin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={errors.name} mb={3}>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter name"
            />
            <FormErrorMessage>{errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.username} mb={3}>
            <FormLabel>Username</FormLabel>
            <Input
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              placeholder="Enter username"
            />
            <FormErrorMessage>{errors.username}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.email} mb={3}>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter email"
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.roles} mb={3}>
            <FormLabel>Role</FormLabel>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Select Roles
              </MenuButton>
              <MenuList height="150px" overflowY="scroll">
                <CheckboxGroup
                  value={formData.roles}
                  onChange={handleRoleChange}
                >
                  <Stack direction="column" p={2}>
                    {[
                      'customer_manager',
                      'partner_manager',
                      'stock_manager',
                      'category_manager',
                      'banner_manager',
                      'product_manager',
                      'membership_manager',
                      'feedback_manager',
                      'complaint_manager',
                      'notification_manager',
                      'offer_manager',
                      'slot_manager',
                      'booking_manager',
                    ].map((role) => (
                      <Checkbox key={role} value={role}>
                        {role
                          .replace('_', ' ')
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </MenuList>
            </Menu>
            <FormErrorMessage>{errors.roles}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.password} mb={3}>
            <FormLabel>Password</FormLabel>
            <Input
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter password"
            />
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Add Admin
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateAdmin;
