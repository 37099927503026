import { useState, useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
// import usePackageActions from 'hooks/usePackageActions';
import useSlotActions from 'hooks/slotApis/useSlotActions';
import useOfferActions from 'hooks/offerApis/useOfferActions';
import useAdminActions from 'hooks/adminApis/useAdminActions';
import useBannerActions from 'hooks/bannerApis/useBannerActions';
import usePartnerActions from 'hooks/partnerApis/usePartnerActions';
import useCategoryActions from 'hooks/categoryApis/useCategoryActions';
import useSubCategoryActions from 'hooks/subCategoryApis/useSubCategoryActions';
import useCustomerActions from 'hooks/customerApis/useCustomerActions';
import useMembershipPlanActions from 'hooks/membershipApis/useMemberPlanActions';
import useNotificationActions from 'hooks/notificationApis/useNotificationActions';
import useCategoryProductActions from 'hooks/categoryProductApis/useCategoryProductActions';

const SwitchToggler = ({ entityType, entityId, initialStatus }) => {
  const toast = useToast({ position: 'top' });
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(initialStatus);
  const { updateSlotStatus } = useSlotActions();
  const { updateAdminStatus } = useAdminActions();
  const { updateOfferStatus } = useOfferActions();
  const { updateBannerStatus } = useBannerActions();
  const { updatePartnerStatus } = usePartnerActions();
  // const { updatePackageStatus } = usePackageActions();
  const { updateCustomerStatus } = useCustomerActions();
  const { updateCategoryStatus } = useCategoryActions();
  const { updateSubCategoryStatus } = useSubCategoryActions();
  const { updateNotificationStatus } = useNotificationActions();
  const { updateMembershipPlanStatus } = useMembershipPlanActions();
  const { updateCategoryProductStatus } = useCategoryProductActions();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    setIsActive(initialStatus);
  }, [initialStatus]);

  const handleConfirmToggle = async () => {
    const newIsActive = !isActive;
    // Optimistically update the UI
    setIsActive(newIsActive);

    let updateStatusFunction;
    switch (entityType) {
      case 'admin':
        updateStatusFunction = updateAdminStatus;
        break;
      case 'customer':
        updateStatusFunction = updateCustomerStatus;
        break;
      case 'partner':
        updateStatusFunction = updatePartnerStatus;
        break;
      case 'category':
        updateStatusFunction = updateCategoryStatus;
        break;
      case 'sub-category':
        updateStatusFunction = updateSubCategoryStatus;
        break;
      case 'banner':
        updateStatusFunction = updateBannerStatus;
        break;
      case 'product':
        updateStatusFunction = updateCategoryProductStatus;
        break;
      // case 'package':
      //   updateStatusFunction = updatePackageStatus;
      //   break;
      case 'membership-plan':
        updateStatusFunction = updateMembershipPlanStatus;
        break;
      case 'offer':
        updateStatusFunction = updateOfferStatus;
        break;
      case 'slot':
        updateStatusFunction = updateSlotStatus;
        break;
      case 'notification':
        updateStatusFunction = updateNotificationStatus;
        break;
      default:
        throw new Error(`Unsupported entity type: ${entityType}`);
    }

    setIsLoading(true);
    try {
      const success = await updateStatusFunction(entityId, newIsActive);
      if (!success) {
        // Revert the UI update if the API request fails
        setIsActive(!newIsActive);
      }
    } catch (error) {
      console.error(`Error updating ${entityType} status:`, error);
      // Revert the UI update if the API request fails
      setIsActive(!newIsActive);
      toast({
        title: 'Update Failed',
        description: error.message || `Failed to update ${entityType} status.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      onClose(); // Close the confirmation dialog
    }
  };

  const handleToggle = () => {
    onOpen();
  };

  return (
    <>
      <FormControl display="flex" alignItems="center">
        <Switch
          id={`status-switch-${entityType}-${entityId}`}
          isChecked={isActive}
          onChange={handleToggle}
          isDisabled={isLoading}
          sx={{
            transform: 'scale(0.9)',
          }}
        />
        <Text ml={3} fontWeight="medium">
          {isActive ? 'Active' : 'Inactive'}
        </Text>
      </FormControl>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Action
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to {isActive ? 'deactivate' : 'activate'}{' '}
              this {entityType}?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmToggle}
                ml={3}
                isLoading={isLoading}
              >
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default SwitchToggler;
