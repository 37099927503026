'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import usePagination from 'hooks/usePagination';
import useCustomer from 'hooks/customerApis/useCustomer';
import ReusableTable from 'components/reusableTable/ReusableTable';
import useNotification from 'hooks/notificationApis/useNotification';
import EditButton from 'components/popups/editButtonPopup/EditButton';
import Pagination from '../../../../components/pagination/Pagination';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import SwitchToggler from 'components/popups/updateStatusPopup/SwitchToggler';
import useNotificationActions from 'hooks/notificationApis/useNotificationActions';
import GenericDetailView from 'components/popups/GenericDetailView/GenericDetailView';
import CreateNotificationPopup from 'components/popups/notificationPopup/CreateNotificationPopup';
import usePartner from 'hooks/partnerApis/usePartner';
import EditNotificationDetails from 'components/popups/notificationPopup/EditNotificationDetials';
import { useSearch } from 'contexts/SearchContext';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const toast = useToast({ position: 'top' });
  const { customers, fetchCustomers } = useCustomer();
  const { partners, fetchPartners } = usePartner();
  const { notifications, error, fetchNotification, registerNotification } =
    useNotification();
  const { deleteNotification, updateNotificationDetails } =
    useNotificationActions();
  const { searchResults, searchQuery } = useSearch();
  // console.log(searchResults);
  const totalPages = searchQuery
    ? searchResults?.totalPages || 1
    : notifications?.totalPages || 1;
  const { currentPage, handlePageChange } = usePagination(totalPages);

  React.useEffect(() => {
    if (!searchQuery) {
      fetchNotification(currentPage);
      fetchCustomers();
      fetchPartners();
    }
  }, [fetchNotification, currentPage, fetchCustomers, searchQuery]);

  const handleAddNotification = async (formData) => {
    try {
      await registerNotification(formData);
      await fetchNotification(currentPage);
    } catch (error) {
      toast({
        title: 'Adding Failed',
        description: error.message || 'Failed to add notification.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditProduct = async (partnerId, userData) => {
    try {
      await updateNotificationDetails(partnerId, userData);
      await fetchNotification(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteNotification(id);
      await fetchNotification(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to delete notification.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return notifications.data || [];
  }, [notifications.data, searchResults.data, searchQuery]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700" width="35px">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('image', {
      id: 'image',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          IMAGE
        </Text>
      ),
      cell: (info) => {
        return (
          <Flex align="center" color={textColor}>
            <GenericDetailView
              type="image"
              content={info.getValue()}
              title="Image Preview"
              imageAlt="Notification Image"
            />
          </Flex>
        );
      },
    }),
    columnHelper.accessor('title', {
      id: 'title',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          TITLE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('body', {
      id: 'body',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          BODY
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            <GenericDetailView
              type="text"
              content={info.getValue() || 'NA'}
              title="Details"
              maxChars={15}
              truncateType="chars"
            />
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('targetAudience', {
      id: 'targetAudience',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          TARGET AUD.
        </Text>
      ),
      cell: ({ row }) => {
        // Extract the targetAudience array from row.original
        const targetAudience = row.original.targetAudience || [];

        // Map through the targetAudience array to get the names
        const audienceNames = targetAudience
          .map((audience) => audience.name)
          .join(', ');

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              width="150px"
            >
              <GenericDetailView
                type="text"
                content={audienceNames || 'NA'}
                title="Targeted Audience"
                maxChars={15}
                truncateType="chars"
              />
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('notificationDate', {
      id: 'notificationDate',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          DATE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('notificationTime', {
      id: 'notificationTime',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          TIME
        </Text>
      ),
      cell: (info) => {
        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              whiteSpace="nowrap"
            >
              {info.getValue() || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),
    // columnHelper.accessor('isActive', {
    //   id: 'isActive',
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: '11px', lg: '13px' }}
    //       color={textColor}
    //     >
    //       STATUS
    //     </Text>
    //   ),
    //   cell: ({ row }) => (
    //     <Flex align="center">
    //       <SwitchToggler
    //         entityType="notification"
    //         entityId={row.original._id}
    //         initialStatus={row.original.isActive}
    //       />
    //     </Flex>
    //   ),
    // }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
      cell: ({ row }) => {
        const initialData = row.original;
        return (
          <Flex align="center">
            <EditButton
              entityType="notification"
              EditComponent={EditNotificationDetails}
              initialData={initialData}
              onConfirm={(updatedData) =>
                handleEditProduct(initialData._id, updatedData)
              }
            />
            {row.original.isDeleted ? (
              <Text color="gray.400" fontStyle="italic">
                Deleted
              </Text>
            ) : (
              <>
                <DeleteButton
                  entityType="notification"
                  entityId={row.original._id}
                  onDelete={() => handleDelete(row.original._id)}
                />
              </>
            )}
          </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description: notifications.message || 'Failed to fetch notifications',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <CreateNotificationPopup
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddNotification}
          customers={customers.data}
          partners={partners.data}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
