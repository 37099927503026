import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';

const AddressDetailPopup = ({ address, title = 'Address Details' }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = 'secondaryGray.900';

  const addressFields = {
    'Building Name': address?.buildingName,
    'Floor/House No':
      address?.floorNo && address?.houseNo
        ? `${address.floorNo}/${address.houseNo}`
        : address?.houseNo || address?.floorNo,
    Street: address?.street,
    Locality: address?.locality,
    Landmark: address?.landmark,
    City: address?.city,
    State: address?.state,
    Pincode: address?.pincode,
    Country: address?.country,
  };

  return (
    <>
      <Text
        fontSize="sm"
        fontWeight="700"
        color={textColor}
        cursor="pointer"
        onClick={onOpen}
        whiteSpace="nowrap"
        textDecoration="underline"
      >
        View Address
      </Text>

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={2}>
              {Object.entries(addressFields)
                .filter(([_, value]) => value)
                .map(([key, value]) => (
                  <Text key={key} fontSize="md" fontWeight="500">
                    <b>{key}:</b> {value}
                  </Text>
                ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddressDetailPopup;
