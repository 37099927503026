import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import { useLoading } from 'contexts/LoadingContext';

const useCustomerActions = () => {
  const { setIsLoading } = useLoading();
  const toast = useToast({ position: 'top' });
  const { token } = useAuth();

  const updateCustomerStatus = async (customerId, newStatus) => {
    // setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/customer/change-status/?id=${customerId}`,
        { isActive: newStatus },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Status Updated',
          description: 'Customer status has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message || 'Failed to update customer status.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update customer status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
  };

  const deleteCustomer = async (id) => {
    setIsLoading(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/customer/delete/?id=${id}`,
        { isDeleted: true },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Customer Deleted',
          description:
            response.data.message || 'Customer has been deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          description:
            response.data.message || 'Customer has been deleted successfully.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting customer:', error);
      toast({
        title: 'Delete Failed',
        description: error.message || 'Failed to delete customer.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const downloadExcel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/customer/download-excel`,
        {
          responseType: 'blob',
        },
      );

      // Step 2: Create a URL for the blob (the Excel file)
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Step 3: Create a link to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'customers.xlsx'); // File name
      document.body.appendChild(link);
      link.click(); // Trigger the download
      link.parentNode.removeChild(link); // Clean up the DOM

      // Optionally, release the object URL after use
      window.URL.revokeObjectURL(url);

      // Success toast
      toast({
        title: 'Download successful!',
        description: 'Customer data has been downloaded.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Error toast
      toast({
        title: 'Download failed!',
        description:
          'Something went wrong while downloading the customer data.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return { updateCustomerStatus, deleteCustomer, downloadExcel };
};

export default useCustomerActions;
