import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Textarea,
  Flex,
  Image,
  VStack,
} from '@chakra-ui/react';
import { handleImageUpload } from 'utils/handleImageUpload';
import useSubCategory from 'hooks/subCategoryApis/useSubCategory';

const CategoryProductPopup = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  categories,
}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    images: [],
    price: '',
    duration: '',
    durationUnit: 'min',
    discountPercentage: '',
    details: '',
    position: '',
    categoryId: '',
    subcategoryId: '',
  });
  const { subCategoryById, fetchSubCategoryByCategoryId } = useSubCategory();

  useEffect(() => {
    if (formData.categoryId) {
      fetchSubCategoryByCategoryId(formData.categoryId);
    }
  }, [fetchSubCategoryByCategoryId, formData.categoryId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorMsg = '';
    if (name === 'price' && (isNaN(value) || value.trim() === '')) {
      errorMsg = 'Price must be a valid number';
    } else if (!value.trim()) {
      errorMsg = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    }
    setErrors((prev) => ({ ...prev, [name]: errorMsg }));
  };

  const validateForm = () => {
    const newErrors = {};
    [
      'name',
      'price',
      'duration',
      'discountPercentage',
      'details',
      'position',
      'categoryId',
    ].forEach((field) => {
      if (!formData[field].trim())
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle submit logic
  const handleSubmit = async () => {
    if (validateForm()) {
      const finalFormData = new FormData();
      for (const key in formData) {
        finalFormData.append(key, formData[key]);
      }
      try {
        await onConfirm(finalFormData); // Call the API
        onClose(); // Close the modal or clear the form
      } catch (error) {
        console.error('Error adding product:', error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter name"
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.price}>
              <FormLabel>Price</FormLabel>
              <Input
                name="price"
                type="number"
                value={formData.price}
                onChange={handleInputChange}
                placeholder="Enter price"
              />
              <FormErrorMessage>{errors.price}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.duration}>
              <FormLabel>Duration</FormLabel>
              <Flex>
                <Input
                  name="duration"
                  type="number"
                  value={formData.duration}
                  onChange={handleInputChange}
                  placeholder="Enter duration"
                  mr={2}
                />
                <Select
                  name="durationUnit"
                  value={formData.durationUnit}
                  onChange={handleInputChange}
                  width="100px"
                >
                  <option value="min">min.</option>
                  <option value="hr">hr.</option>
                </Select>
              </Flex>
              <FormErrorMessage>{errors.duration}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.discountPercentage}>
              <FormLabel>Discount Percentage</FormLabel>
              <Input
                name="discountPercentage"
                type="number"
                value={formData.discountPercentage}
                onChange={handleInputChange}
                placeholder="Enter discount percentage"
              />
              <FormErrorMessage>{errors.discountPercentage}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.position}>
              <FormLabel>Position</FormLabel>
              <Input
                name="position"
                type="number"
                value={formData.position}
                onChange={handleInputChange}
                placeholder="Enter position"
              />
              <FormErrorMessage>{errors.position}</FormErrorMessage>
            </FormControl>

            {/* {categories.data?.length > 0 ? ( */}
            <FormControl isInvalid={!!errors.categoryId}>
              <FormLabel>Category</FormLabel>
              <Select
                name="categoryId"
                value={formData.categoryId}
                onChange={handleInputChange}
                placeholder="Select category"
              >
                {categories.data?.map((cat) => (
                  <option key={cat._id} value={cat._id}>
                    {cat.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.categoryId}</FormErrorMessage>
            </FormControl>

            {subCategoryById.data?.length > 0 && (
              <FormControl>
                <FormLabel>Sub Category</FormLabel>
                <Select
                  name="subcategoryId"
                  value={formData.subcategoryId}
                  onChange={handleInputChange}
                  placeholder="Select sub category"
                >
                  {subCategoryById.data?.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl isInvalid={!!errors.details}>
              <FormLabel>Details</FormLabel>
              <Textarea
                name="details"
                rows={15}
                value={formData.details}
                onChange={handleInputChange}
                placeholder="Enter details"
              />
              <FormErrorMessage>{errors.details}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.image}>
              <FormLabel>Image</FormLabel>
              <Input
                name="image"
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleImageUpload(e, setErrors, setFormData, setImagePreview)
                }
              />
              <FormErrorMessage>{errors.image}</FormErrorMessage>
            </FormControl>
            {imagePreview && (
              <Image
                src={imagePreview}
                alt="Product Preview"
                maxWidth="150px"
              />
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            isLoading={isLoading}
            onClick={handleSubmit}
            ml={3}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CategoryProductPopup;
