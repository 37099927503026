import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Image,
  Box,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import { handleImageUpload } from 'utils/handleImageUpload';

const PartnerPopup = ({ isOpen, onClose, onConfirm }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    image: '',
    pincode: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    image: '',
    pincode: '',
  });

  const [pincodes, setPincodes] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (value.trim() === '') {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    if (!formData.phone.trim()) newErrors.phone = 'Phone is required';
    if (!formData.address.trim()) newErrors.address = 'Address is required';
    if (pincodes.length === 0)
      newErrors.pincode = 'At least one pincode is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const finalFormData = new FormData();
      for (const key in formData) {
        if (key !== 'pincode') {
          finalFormData.append(key, formData[key]);
        }
      }
      finalFormData.append('pincode', pincodes.join(','));
      try {
        await onConfirm(finalFormData);
        onClose();
      } catch (error) {
        console.error('Error adding partner:', error);
      }
    }
  };

  const handleAddPincode = () => {
    if (
      formData.pincode.trim() &&
      !pincodes.includes(formData.pincode.trim())
    ) {
      setPincodes((prev) => [...prev, formData.pincode.trim()]);
      setFormData((prev) => ({ ...prev, pincode: '' }));
      setErrors((prev) => ({ ...prev, pincode: '' }));
    }
  };

  const handleRemovePincode = (pincodeToRemove) => {
    setPincodes((prev) =>
      prev.filter((pincode) => pincode !== pincodeToRemove),
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Partner</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={errors.name} mb={3}>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter name"
            />
            <FormErrorMessage>{errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.email} mb={3}>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter email"
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.phone} mb={3}>
            <FormLabel>Phone</FormLabel>
            <Input
              name="phone"
              type="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Enter phone"
            />
            <FormErrorMessage>{errors.phone}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.address} mb={3}>
            <FormLabel>Address</FormLabel>
            <Input
              name="address"
              type="text"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Enter address"
            />
            <FormErrorMessage>{errors.address}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.image} mb={3}>
            <FormLabel>Upload Image</FormLabel>
            <Input
              name="image"
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleImageUpload(e, setErrors, setFormData, setImagePreview)
              }
            />
            <FormErrorMessage>{errors.image}</FormErrorMessage>
          </FormControl>
          {imagePreview && (
            <Image src={imagePreview} alt="Preview" maxWidth="100px" mb={3} />
          )}
          <FormControl isInvalid={errors.pincode} mb={3}>
            <FormLabel>Pincode</FormLabel>
            <Box display="flex">
              <Input
                name="pincode"
                value={formData.pincode}
                onChange={handleInputChange}
                placeholder="Enter pincode"
              />
              <Button ml={2} onClick={handleAddPincode}>
                Add
              </Button>
            </Box>
            <FormErrorMessage>{errors.pincode}</FormErrorMessage>
          </FormControl>
          <Box mt={3}>
            {pincodes.map((pincode, index) => (
              <Tag
                key={index}
                size="md"
                borderRadius="full"
                variant="solid"
                colorScheme="blue"
                mb={1}
                mr={1}
              >
                <TagLabel>{pincode}</TagLabel>
                <TagCloseButton onClick={() => handleRemovePincode(pincode)} />
              </Tag>
            ))}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Add Partner
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PartnerPopup;
